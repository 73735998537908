import { createActions } from 'redux-actions';
import { DefaultRoutine } from 'src/utils/redux';

const Actions = createActions({
    WORKOUTS: {
        LIST_ENROLLMENTS: DefaultRoutine,
        LIST_CURRENT_SERIES: DefaultRoutine,
    },
});

export default Actions;
