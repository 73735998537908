import AuthSelectors from 'src/state/auth/selectors';
import AccountSelectors from 'src/state/account/selectors';
import Content from 'src/state/content';
import History from 'src/state/history';

export default {
    auth: AuthSelectors,
    account: AccountSelectors,
    content: Content.Selectors,
    history: History.Selectors,
};
