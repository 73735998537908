import { set } from 'lodash';
import { handleActionsImmer, DefaultInitialState } from 'src/utils/redux';
import Actions from 'src/state/root-actions';

const {
    content: { find },
} = Actions;

const initialState = {
    find: { ...DefaultInitialState(), data: {} },
    current: null,
};

const reducer = {
    [find.success]: (draft, { response: { content }, contentType }) => {
        set(draft, `data.${contentType}`, content);
        set(draft, `data.${contentType}.loadedAt`, Date.now());
    },
};

const Reducer = handleActionsImmer(reducer, initialState, { find });

export default Reducer;
