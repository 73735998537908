import { all, fork } from 'redux-saga/effects';

import AccountSagas from 'src/state/account/sagas';
import AuthSagas from 'src/state/auth/sagas';
import Content from 'src/state/content';
import History from 'src/state/history';

export default function* rootSaga() {
    yield all([
        fork(AccountSagas),
        fork(AuthSagas),
        fork(Content.Sagas),
        fork(History.Sagas),
    ]);
}
