/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import { store } from 'src/state/store';

import Actions from 'src/state/root-actions';

import 'src/styles/global.scss';
import 'pure-react-carousel/dist/react-carousel.es.css';

export { default as wrapRootElement } from 'src/state/ReduxWrapper';
export { default as wrapPageElement } from 'src/components/layout/Layout';

export const onClientEntry = () => {
    store.dispatch(Actions.initialPageLoad.trigger());
};