import Pvolve from '@pvolve/sdk';

import { createSelector } from 'reselect';

const Selectors = {
    idToken: (state) => state.tokens?.id,
    tokens: (state) => state.tokens,
};

Selectors.loggedIn = createSelector(Selectors.idToken, (idToken) => !!idToken);

Selectors.user = createSelector(Selectors.idToken, (id) =>
    id
        ? {
            ...Pvolve.api.tokens.decode(id),
        }
        : null
);

export default Selectors;
