import { createActions } from 'redux-actions';
import { DefaultRoutine } from 'src/utils/redux';

import AccountActions from 'src/state/account/actions';
import AuthActions from 'src/state/auth/actions';
import ContentActions from 'src/state/content/actions';
import HistoryActions from 'src/state/history/actions';

const AppActions = createActions({
    INITIAL_PAGE_LOAD: DefaultRoutine,
});

export default {
    ...AppActions,
    account: AccountActions.account,
    auth: AuthActions.auth,
    content: ContentActions.content,
    history: HistoryActions.workouts,
};
