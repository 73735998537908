import Pvolve from '@pvolve/sdk';
import queryString from 'query-string';

import { navigate } from 'gatsby';
import { takeEvery, put } from 'redux-saga/effects';
import { routineSaga } from 'src/utils/sagas';

import Actions from 'src/state/root-actions';

const {
    auth: {
        changePassword,
        login,
        signup,
        processOauthCode,
        logout,
        forgotPassword,
        confirmForgotPassword,
    },
} = Actions;

class AuthSaga {
    init = function* () {
        if (typeof window !== 'undefined') {
            const { code } = queryString.parse(document.location.search || '');
            if (code) {
                yield put(processOauthCode.trigger({ code }));
            }
        }
    };

    login = routineSaga({
        routine: login,
        *request({ payload: { email, password } }) {
            const { tokens } = yield Pvolve.api.auth.login(email, password);

            Pvolve.api.tokens.updateTokens(tokens);
            return tokens;
        },
    });

    signup = routineSaga({
        routine: signup,
        *request({ payload: { email, firstName, lastName, password } }) {
            const { tokens } = yield Pvolve.api.auth.register(email, password, {
                given_name: firstName,
                family_name: lastName,
                /**
                 *
                 * TODO: optIn
                 * @sadaf spoke to @adamk and he's going to figure out how
                 * to handle this - it may or may not belong in cognito
                 *
                 * */
            });

            Pvolve.api.tokens.updateTokens(tokens);
            return tokens;
        },
    });

    forgotPassword = routineSaga({
        routine: forgotPassword,
        *request({ payload: { email } }) {
            const response = yield Pvolve.api.cognito.forgotPassword(email);
            return response;
        },
    });

    confirmForgotPassword = routineSaga({
        routine: confirmForgotPassword,
        *request({ payload: { email, code, password } }) {
            return yield Pvolve.api.cognito.confirmForgotPassword(
                email,
                password,
                code
            );
        },
    });

    logout = routineSaga({
        routine: logout,
        *request() {
            yield Pvolve.api.tokens.clear();
        },
    });

    changePassword = routineSaga({
        routine: changePassword,
        *request({ payload: { oldPassword, password } }) {
            yield Pvolve.api.cognito.changePassword(oldPassword, password);
        },
    });

    processOauthCode = routineSaga({
        routine: processOauthCode,
        *request({ payload: { code } }) {
            if (typeof window !== 'undefined') {
                const redirectUri = window.location.href.split('?')[0];
                const tokens = yield Pvolve.api.cognito.tokensFromCode(
                    code,
                    redirectUri
                );
                Pvolve.api.tokens.updateTokens(tokens);
                //TODO: should this navigate be moved to onSuccess?
                navigate('/continue');
            }
        },
    });
}

const saga = new AuthSaga();

export default function* combined() {
    yield takeEvery('persist/REHYDRATE', saga.init);
    yield takeEvery(login.trigger, saga.login);
    yield takeEvery(signup.trigger, saga.signup);
    yield takeEvery(logout.trigger, saga.logout);
    yield takeEvery(changePassword.trigger, saga.changePassword);
    yield takeEvery(forgotPassword.trigger, saga.forgotPassword);
    yield takeEvery(confirmForgotPassword.trigger, saga.confirmForgotPassword);
    yield takeEvery(processOauthCode.trigger, saga.processOauthCode);
}
