import { kebabCase } from 'lodash';

export const PRIVATE_ROUTES = ['account'];

export const PLAIN_LAYOUT_ROUTES = ['continue', 'signup', 'loginHelp'];

export const CLIENT_ONLY_ROUTES = ['account'];

export const isPlainLayout = (path) => {
    return new RegExp(PLAIN_LAYOUT_ROUTES.join('|')).test(path);
};

export const isPrivateRoute = (path) => {
    return new RegExp(PRIVATE_ROUTES.join('|')).test(path);
};

export const isAccountPage = (path) => {
    return /^\/account/.test(path);
};

export const navLink = (name) => `/${kebabCase(name)}`;

export const stripTrailingSlash = (url) => url.replace(/\/$/g, '');
