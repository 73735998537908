import _ from 'lodash';
import querystring from 'querystring';

export default class BaseService {

  constructor({ configPath }) {
    this._configPath = configPath;
  }

  config = async () => {
    const config = await this.core.api.config.find(this._configPath);
    return config;
  }
  
  clearTokensAndLogout = async () => {
    this._coreInstance._services.auth.authStore.logout();
    await this._coreInstance._services.tokens.clear();
  };

  get core() {
    return this._coreInstance;
  }

  set core(coreInstance) {
    this._coreInstance = coreInstance;
  }

  rawRequest = async options => {
    const authErrors = ['invalid_token', 'unauthorized', 'invalid_grant'];
    const authErrorStatus = [400, 401];
    
    try {
      const rawResponse = await this.core.requestAdapter.request(options);

      return rawResponse;
    } catch (e) {
      if ( _.includes(authErrorStatus, e?.response?.status) &&
           _.includes(authErrors, e?.response?.data?.message?.toLowerCase() )
      ) {
        this.clearTokensAndLogout();
      } else {
        throw e;
      }
    }
  }

  async request(options) {
    const { data } = await this.rawRequest(options);
    return data;
  }

  authRequest = async ({ tokenType = 'id', headers = {}, ...options }) => {
    const { tokens } = this.core.api;

    const h = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...headers
    };

    if (tokenType !== 'none') {
      await tokens.freshTokens();
      h.Authorization = `Bearer ${tokens[tokenType]}`;
      if(process.env.DEBUG) console.log({options, token: h.Authorization});
    }
    return await this.request({ headers: h, ...options });
  }

  requestRootResource(options = {}) {
    return this.requestResource(undefined, options);
  }

  resolveUrl = async (methodName, opts = {}) => {
    const { params = {}, query } = opts;

    const config = await this.config();

    const urlTemplateString = methodName ? config.resources[methodName] : config.resource;

    const template = _.template(urlTemplateString, {
      interpolate: URL_INTERPOLATION_RULES
    });

    return `${template(params)}${query ? '?' + querystring.stringify(query) : ''}`;
  }

  requestResource = async (methodName, opts = {}) => {
    const { params = {}, queryParams, ...options } = opts;

    if (queryParams) {
      options.params = queryParams;
    }

    const url = await this.resolveUrl(methodName, { params, query: queryParams });
    return await this.authRequest({url, ...options });
  }


}

const URL_INTERPOLATION_RULES = /{([\s\S]+?)}/g;
