import { takeEvery } from 'redux-saga/effects';
import { routineSaga } from 'src/utils/sagas';
import Actions from 'src/state/root-actions';
import Pvolve from '@pvolve/sdk';

const {
    history: { listCurrentSeries, listEnrollments },
} = Actions;

class HistorySaga {
    listEnrollments = routineSaga({
        routine: listEnrollments,
        *request() {
            const enrollments = yield Pvolve.api.workout.listEnrolled();
            return enrollments;
        },
    });

    listCurrentSeries = routineSaga({
        routine: listCurrentSeries,
        *request({ payload: { startEpochTs, seriesId } }) {
            const series = yield Pvolve.api.workout.history(
                startEpochTs,
                seriesId
            );

            return series;
        },
    });
}

const saga = new HistorySaga();

export default function* combined() {
    yield takeEvery(listEnrollments.trigger, saga.listEnrollments);
    yield takeEvery(listCurrentSeries.trigger, saga.listCurrentSeries);
}
