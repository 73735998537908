import { put, call } from 'redux-saga/effects';

export function routineSaga({
    filters = [],
    routine,
    request,
    afterSuccess,
    onError,
    internal,
}) {
    return function* (action) {
        const { payload = {} } = action;
        const { onFailure, onFulfill, onSuccess } = payload;

        try {
            for (const filter of filters) {
                const allow = yield call(filter, action);
                if (!allow) {
                    return;
                }
            }

            yield put(routine.request(action.payload));
            const returnValue = yield request(action);
            yield put(routine.success(returnValue));
            if (afterSuccess) {
                yield call(afterSuccess, returnValue);
            }
            if (onSuccess) {
                yield call(onSuccess, returnValue);
            }
        } catch (error) {
            if (onError) {
                yield call(onError, error);
            } else {
                const { errors } = error?.response?.data || {};
                const internalError = error?.internal || internal;

                if (onFailure) {
                    const message = error?.response?.data?.message;
                    yield call(onFailure, message);
                }

                if (errors) {
                    yield put(
                        routine.failure({
                            error: errors,
                            internal: internalError,
                        })
                    );
                } else {
                    yield put(
                        routine.failure({ error, internal: internalError })
                    );
                }
            }
        } finally {
            if (onFulfill) {
                yield call(onFulfill);
            }
            yield put(routine.fulfill());
        }
    };
}

export function sendTo(actionFunction, payloadOptions) {
    return function* ({ payload }) {
        yield put(actionFunction({ ...payload, ...payloadOptions }));
    };
}

export default {};
