import { concat } from 'lodash';

const SHARED_NAV_ITEMS = [
    {
        label: 'Locations',
        subnav: [
            {
                label: 'About',
                pathname: '/pages/studio',
            },
            {
                label: 'New York',
                pathname: '/pages/new-york-studio',
            },
            {
                label: 'Chicago',
                pathname: '/pages/chicago-studio',
            },
            {
                label: 'Los Angeles',
                pathname: '/pages/los-angeles-studio',
            },
        ],
    },
    {
        label: 'Shop',
        subnav: [
            {
                label: 'All',
                pathname: '/collections/all',
            },
            {
                label: 'Kits',
                pathname: '/collections/kits-and-bundles',
            },
            {
                label: 'Equipment',
                pathname: '/collections/equipment',
            },
            {
                label: 'Streaming Access',
                pathname: '/products/streaming-access',
            },
            {
                label: 'Apparel',
                pathname: '/collections/apparel',
            },
            {
                label: 'Gifts',
                pathname: '/products/gift-card',
            },
            {
                label: 'Under $50',
                pathname: '',
            },
        ],
    },
    {
        label: 'Blog',
        subnav: [
            {
                label: 'All',
                pathname: '/blogs/all',
            },
            {
                label: 'Fitness',
                pathname: '/blogs/all/tagged/fitness',
            },
            {
                label: 'Lifestyle',
                pathname: '/blogs/all/tagged/lifestyle',
            },
            {
                label: 'Nutrition',
                pathname: '/blogs/all/tagged/nutrition',
            },
            {
                label: 'Travel',
                pathname: '/blogs/all/tagged/travel',
            },
            {
                label: 'Testimonies',
                pathname: '/blogs/all/tagged/testimonial',
            },
        ],
    },
];

const LOGGED_IN_NAV_ITEMS = [
    {
        label: 'On Demand',
        subnav: [
            {
                label: 'My Plan',
                pathname: '/workouts/my-plan',
                isGatsby: true,
            },
            {
                label: 'Library',
                pathname: '/workouts/library',
                isGatsby: true,
            },
        ],
    },
];

const LOGGED_OUT_NAV_ITEMS = [
    {
        label: 'Method',
        pathname: '/pages/method',
    },
    {
        label: 'On Demand',
        subnav: [
            {
                label: 'How It Works',
                pathname: '/pages/stream-your-workout',
            },
            {
                label: 'Library',
                pathname: '/workouts/library',
                isGatsby: true,
            },
        ],
    },
];

export const LOGGED_IN_NAV = concat(LOGGED_IN_NAV_ITEMS, SHARED_NAV_ITEMS);
export const LOGGED_OUT_NAV = concat(LOGGED_OUT_NAV_ITEMS, SHARED_NAV_ITEMS);
