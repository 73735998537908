import _ from 'lodash';

export const CONFIG_UPDATE_ACTION = 'SYNC/CONFIG/UPDATE';

export default class ReduxConfigStore {

  constructor(store, options = {}) {
    this.storeKey = options.storeKey || 'config';
    this.store = store;
  }

  updateConfig(config, metadata) {
    const { store } = this;
    store.dispatch({
      type: CONFIG_UPDATE_ACTION,
      payload: { config, metadata }
    });
  }

  clear() {
    this.updateConfig();
  }

  get rawConfig() {
    const { store, storeKey } = this;
    return _.get(store.getState(), storeKey, {});
  }

  get config() {
    return this.rawConfig.config; 
  }

  get metadata() {
    return this.rawConfig.metadata || {};
  }
}
