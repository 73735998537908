import ConfigService from './ConfigService';

export default class HttpConfigService extends ConfigService {

  constructor(endpoint) {
    super();
    this._endpoint = endpoint;
  }

  async refreshConfig() {
    const { status, headers: { etag }, data } = await this.rawRequest({
      url: `${this._endpoint}?${new Date().getTime()}`,
    });
    if(status >= 200 && status < 300) {
      this.updateConfig(data, etag);
    }
    return this;
  }

}
