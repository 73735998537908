import Pvolve from '@pvolve/sdk';
import { takeEvery } from 'redux-saga/effects';

import Actions from 'src/state/root-actions';
import { routineSaga } from 'src/utils/sagas';

const {
    account: { getAllAttrs, saveUserAttrs, saveEmailAttrs, getEmailAttrs },
} = Actions;

class AccountSaga {
    getAllAttrs = routineSaga({
        routine: getAllAttrs,
        *request() {
            return yield Pvolve.api.account.getAllAttributes();
        },
    });

    getEmailAttrs = routineSaga({
        routine: getEmailAttrs,
        *request() {
            return yield Pvolve.api.account.getEmailAttributes();
        },
    });

    saveUserAttrs = routineSaga({
        routine: saveUserAttrs,
        *request({
            payload: {
                firstName,
                lastName,
                email,
                version,
                birthday,
                gender,
                phone,
            },
        }) {
            return yield Pvolve.api.account.saveUserAttributes(version, {
                firstName,
                lastName,
                email,
                birthday,
                gender,
                phone,
            });
        },
    });

    saveEmailAttrs = routineSaga({
        routine: saveEmailAttrs,
        *request({ payload: { version, optOut } }) {
            return yield Pvolve.api.account.saveEmailAttributes(version, {
                optOut,
            });
        },
    });
}

const saga = new AccountSaga();

export default function* combined() {
    yield takeEvery(getAllAttrs.trigger, saga.getAllAttrs);
    yield takeEvery(saveUserAttrs.trigger, saga.saveUserAttrs);
    yield takeEvery(saveEmailAttrs.trigger, saga.saveEmailAttrs);
    yield takeEvery(getEmailAttrs.trigger, saga.getEmailAttrs);
}
