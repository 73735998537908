import React from 'react';
import Helmet from 'react-helmet';

import Header from 'src/components/layout/Header';
import { isPlainLayout, isAccountPage } from 'src/utils/route-utils';

/**
 * Default Semantic UI Theme
 * TODO: replace with custom theme
 * @see https://react.semantic-ui.com/usage#theme
 *
 */
import 'semantic-ui-less/semantic.less';

const Layout = ({ element, props: { path } }) => {
    const plainLayout = isPlainLayout(path);
    const accountPage = isAccountPage(path);

    return (
        <div className="pageContainer">
            <Helmet>
                {/**
                 * Responsive viewports
                 * @see https://developer.mozilla.org/en-US/docs/Mozilla/Mobile/Viewport_meta_tag
                 */}
                <meta
                    name="viewport"
                    content="minimum-scale=1, initial-scale=1, width=device-width"
                />
            </Helmet>
            {!plainLayout && <Header siteTitle="P.volve" path={path} />}
            {accountPage && (
                <div
                    style={{
                        width: '100%',
                        backgroundColor: 'white',
                        marginTop: '-25px',
                        position: 'absolute',
                        height: '70px',
                    }}
                ></div>
            )}
            <main>
                <div className="bodyContainer">{element}</div>
            </main>
            {!plainLayout && (
                <>
                    <div style={{ height: '40px', width: '100%' }}></div>
                    <footer style={{ marginTop: '80px' }}>
                        © {new Date().getFullYear()}, Built with
                        {` `}
                        <a href="https://www.gatsbyjs.org">Gatsby</a>
                    </footer>
                </>
            )}
        </div>
    );
};

export default Layout;
