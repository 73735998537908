import { createActions } from 'redux-actions';
import { DefaultRoutine } from 'src/utils/redux';

const Actions = createActions({
    ACCOUNT: {
        GET_ALL_ATTRS: DefaultRoutine,
        SAVE_USER_ATTRS: DefaultRoutine,
        SAVE_EMAIL_ATTRS: DefaultRoutine,
        GET_EMAIL_ATTRS: DefaultRoutine,
    },
});

export default Actions;
