import defaultAxios from 'axios';
import AxiosAdapter from './AxiosAdapter';
import LocalConfigService from './LocalConfigService';
import HttpConfigService from './HttpConfigService';
import LocalTokenStore from './LocalTokenStore';
import MemoryConfigStore from './MemoryConfigStore';
import AppSyncAdapter from './AppSyncAdapter';

export default function configure(coreInstance, {
  config,
  axios,
  requestAdapter,
  tokenStore,
  configService,
  configUri,
  AppSyncClientClass,
  configStore,
  authStore,
}) {

  if(configUri) {
    configService = new HttpConfigService(configUri);
  } else if(config) {
    configService = new LocalConfigService(config);
  }

  configService.configStore = configStore || new MemoryConfigStore();

  coreInstance.register('config', configService);
  coreInstance.api.tokens.tokenStore = tokenStore || new LocalTokenStore();
  coreInstance.requestAdapter = requestAdapter || new AxiosAdapter(axios || defaultAxios);
  coreInstance.api.auth.authStore = authStore;

  if(AppSyncClientClass) {
    coreInstance.graphQLAdapter = new AppSyncAdapter(coreInstance, AppSyncClientClass);
  }
}
