import React from 'react';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { store, persistor } from './store';

/**
 * Wrap application with Redux Provider
 * PersistGate to prohibit rendering of UI until state has been rehydrated from storage
 */
export default ({ element }) => (
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            {element}
        </PersistGate>
    </Provider>
);
