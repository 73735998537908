import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { connect } from 'react-redux';
import { Link } from 'gatsby';
import { Dropdown } from 'semantic-ui-react';

import withLocation from 'src/components/withLocation';
import Selectors from 'src/state/root-selectors';
import headerStyles from 'src/styles/header.module.scss';

import { isAccountPage } from 'src/utils/route-utils';

const HeaderMenu = ({ loggedIn, user, location: { pathname } }) => {
    const title = user?.given_name ? user.given_name : 'My Account';
    const [isMenuOpen, setMenuOpen] = useState(false);

    const openMenu = () => setMenuOpen(true);
    const closeMenu = () => setMenuOpen(false);

    let activeMenuItem;

    if (isAccountPage(pathname)) {
        if (pathname.match(/subscription/)) {
            activeMenuItem = 'subscription';
        } else if (pathname.match(/orders/)) {
            activeMenuItem = 'orders';
        } else if (pathname.match(/communications/)) {
            activeMenuItem = 'communications';
        } else if (pathname.match(/^\/account\/?$/)) {
            activeMenuItem = 'account';
        }
    }

    if (!loggedIn) {
        return (
            <Link className={headerStyles.headerLogin} to="/continue">
                Login / SignUp
            </Link>
        );
    }

    return (
        <Dropdown
            className={headerStyles.headerDropdownTitle}
            text={title}
            onBlur={closeMenu}
            onFocus={openMenu}
            icon={isMenuOpen ? 'angle up' : 'angle down'}
            direction="left"
            floating
        >
            <Dropdown.Menu
                className={headerStyles.headerDropdownMenu}
                size="large"
            >
                <Dropdown.Item
                    className={headerStyles.headerDropdownItem}
                    as={Link}
                    active={activeMenuItem === 'account'}
                    text="Account"
                    to="/account"
                />
                <Dropdown.Item
                    className={headerStyles.headerDropdownItem}
                    as={Link}
                    active={activeMenuItem === 'subscription'}
                    text="Subscription"
                    to="/account/subscription"
                />
                <Dropdown.Item
                    className={headerStyles.headerDropdownItem}
                    as={Link}
                    active={activeMenuItem === 'orders'}
                    text="Orders"
                    to="/account/orders"
                />
                <Dropdown.Item
                    className={headerStyles.headerDropdownItem}
                    as={Link}
                    active={activeMenuItem === 'communications'}
                    text="Communications"
                    to="/account/communications"
                />
                <Dropdown.Item
                    className={headerStyles.headerDropdownItem}
                    as={Link}
                    text="Logout"
                    to="/logout"
                />
            </Dropdown.Menu>
        </Dropdown>
    );
};

HeaderMenu.propTypes = {
    title: PropTypes.string,
    location: PropTypes.object.isRequired,
    loggedIn: PropTypes.bool,
    user: PropTypes.object,
};

HeaderMenu.defaultProps = {
    title: 'My Account',
    loggedIn: false,
    user: {},
};

const mapStateToProps = (state) => ({
    loggedIn: Selectors.auth.loggedIn(state),
    user: Selectors.auth.user(state),
});

export default connect(mapStateToProps)(withLocation(HeaderMenu));
