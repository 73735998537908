import { times, random } from 'lodash';
import { createSelector } from 'reselect';
import faker from 'faker';

const smallPrice = () => (faker.commerce.price() / 100).toFixed(2);
const Selectors = {
    orders: createSelector(() => ({
        data: times(random(5, 50), (i) => ({
            id: faker.random.uuid().substr(0, 6).toUpperCase(),
            date: faker.date.past(),
            status: faker.random.arrayElement([
                'processing',
                'shipping',
                'fullfilled',
                'cancelled',
            ]),
            total: faker.finance.amount(),
        })),

        headers: [
            { key: 'id', title: 'Orders' },
            { key: 'date' },
            { key: 'status' },
            { key: 'total' },
        ],
    })),

    orderDetails: createSelector(() => ({
        data: times(random(1, 4), (i) => ({
            sku: faker.random.uuid().substr(0, 6).toUpperCase(),
            amount: faker.commerce.price(),
            quantity: random(1, 3),
            name: faker.random.arrayElement([
                'p.ball',
                'p.band',
                'p.trainer',
                'p.volve slant board',
            ]),
        })),
        fullfilledAt: faker.date.past(),
        trackingId: faker.random.uuid().substr(0, 8).toUpperCase(),
        billingAddress: {
            name: `${faker.name.firstName()} ${faker.name.lastName()}`,
            street: faker.address.streetAddress(),
            city: faker.address.city(),
            state: faker.address.state(),
            zip: faker.address.zipCode(),
        },
        shippingAddress: {
            name: `${faker.name.firstName()} ${faker.name.lastName()}`,
            street: faker.address.streetAddress(),
            city: faker.address.city(),
            state: faker.address.state(),
            zip: faker.address.zipCode(),
        },
        surcharges: [
            {
                key: 'Subtotal',
                amount: smallPrice(),
            },
            {
                key: 'CODE123',
                amount: smallPrice(),
            },
            {
                key: 'Ground Shipping',
                amount: smallPrice(),
            },
            {
                key: 'CA County Tax (0.25%)',
                amount: smallPrice(),
            },
            {
                key: 'CA Special Tax (1%)',
                amount: smallPrice(),
            },
            {
                key: 'CA State Tax (6%)',
                amount: smallPrice(),
            },
        ],
        total: faker.commerce.price(),
        headers: [
            { key: 'name', title: 'product' },
            { key: 'sku' },
            { key: 'price', title: 'amount' },
            { key: 'quantity', title: '#', align: 'right' },
            { key: 'total' },
        ],
    })),
};

export default Selectors;
