import React from 'react';

import { Grid, Image } from 'semantic-ui-react';

import HeaderMenu from 'src/components/layout/HeaderMenu';
import HeaderNav from 'src/components/layout/HeaderNav';

import headerStyles from 'src/styles/header.module.scss';

import logo from 'src/assets/logo-horizontal-no-tagline-1x.png';

const Header = (props) => {
    return (
        <header>
            <div className={headerStyles.headerWrapper}>
                <Grid columns={3}>
                    <Grid.Column width={3}>
                        <Image src={logo} size="small" />
                    </Grid.Column>

                    <Grid.Column width={10}>
                        <HeaderNav {...props} />
                    </Grid.Column>

                    <Grid.Column width={3} textAlign="right">
                        <HeaderMenu />
                    </Grid.Column>
                </Grid>
            </div>
        </header>
    );
};

export default Header;
