import createSagaMiddleware from 'redux-saga';
import cookies from 'js-cookie';
import Pvolve from '@pvolve/sdk';

import { configureWeb } from '@pvolve/sdk/src/redux';
import { applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import { persistStore } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';

import persistedReducer from 'src/state/root-reducer';
import SagaManager from 'src/state/saga-manager';

/**
 * MIDDLEWARES
 */

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

if (process.env.NODE_ENV === `development`) {
    const logger = createLogger({
        predicate: (getState, action) => {
            // log only in browser, not in server
            return process.browser;
        },
    });

    middlewares.push(logger);
}

/**
 * CREATE & PERSIST STORE
 */
export const store = createStore(
    persistedReducer,
    composeWithDevTools(applyMiddleware(...middlewares))
);

SagaManager.startSagas(sagaMiddleware);

configureWeb(Pvolve, {
    configUri: process.env.REMOTE_CONFIG_URL,
    store,
    cookies,
});

export const persistor = persistStore(store);
