import { createActions } from 'redux-actions';
import { DefaultRoutine } from 'src/utils/redux';

const Actions = createActions({
    AUTH: {
        PROCESS_OAUTH_CODE: DefaultRoutine,
        FORGOT_PASSWORD: DefaultRoutine,
        LOGIN: DefaultRoutine,
        RESET_PASSWORD: DefaultRoutine,
        SIGNUP: DefaultRoutine,
        LOGOUT: DefaultRoutine,
        CHANGE_PASSWORD: DefaultRoutine,
        CONFIRM_FORGOT_PASSWORD: DefaultRoutine,
    },
});

export default Actions;
