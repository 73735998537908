import _ from 'lodash';

export const TOKEN_UPDATE_ACTION = 'SYNC/TOKENS/UPDATE';

export default class ReduxTokenStore {

  constructor(store, options = {}) {
    this.storeKey = options.storeKey || 'tokens';
    this.store = store;
  }

  updateTokens(tokens) {
    const { store } = this;
    store.dispatch({
      type: TOKEN_UPDATE_ACTION,
      payload: tokens
    });
  }

  clear() {
    this.updateTokens({
      id: null,
      access: null,
      refresh: null,
    });
  }

  get tokens() {
    const { store, storeKey } = this;
    const tokens = _.get(store.getState(), storeKey);
    return  tokens;
  }

  get id() { return this.tokens.id; }
  get refresh() { return this.tokens.refresh; }
  get access() { return this.tokens.access; }

}
