import { filter, get, set } from 'lodash';
import { Entry, Asset } from 'contentful';
import { IWorkout, ISeries, IBenefit, IBodyFocus, IEquipment, IInstructor, IWorkoutCategory, IWorkoutType, ISeriesCategory, ISeriesChapter } from '../../models/contentful_types';
import { Selector, createSelector } from 'reselect';


export interface IContentState {
  data: {
    workout:  object[];
    series:   object[];
    benefit: object[];
    bodyFocus:object[];
    equipment:object[];
    instructor:object[];
    workoutCategory:object[];
    workoutHistory: object[];
    workoutType:object[];
    seriesCategory:object[];
    seriesChapter:object[];
    asset:object[];
  }
};

export interface IContentSelectors {
  series: IContentSelector<ISeries>;
  workout: WorkoutSelectors,
  benefit: IContentSelector<IBenefit>;
  bodyFocus:  IContentSelector<IBodyFocus>;
  equipment:  IContentSelector<IEquipment>;
  instructor: IContentSelector<IInstructor>;
  workoutCategory: IContentSelector<IWorkoutCategory>;
  workoutType: IContentSelector<IWorkoutType>;
  seriesCategory: IContentSelector<ISeriesCategory>;
  seriesChapter: IContentSelector<ISeriesChapter>;
  asset: IContentSelector<Asset>;
  workoutsBySeries: Selector<IState, IContentListIndex<IWorkout>>;
};

interface IRoutineState {
  loading: boolean;
  fulfilled: boolean;
  error?: any;
  loadedAt: number;
}

interface IDataState<T> extends IRoutineState {
  data: T;
}

interface IEnrollmentItem {
  completed_at?: string;
  series_id: string;
  started_at?: string;
  user_id: string;
  status: string;
}

enum IWorkoutHistoryStatus {
  in_progress = "in_progress"
}

export interface IWorkoutHistoryItem {
  user_id: string,
  session_id: string,
  workout_id: string,
  video_id: string,
  series_id: string,
  started_at: string,
  completed_at: string,
  target_seconds: number,
  total_seconds: number,
  status: IWorkoutHistoryStatus,
  percent_complete: number,
  timecode?: number,
}

interface IFavoriteResults {
  instructors?: string[];
  series?: string[];
  videos?: string[];
}

interface IFavoriteState extends IDataState<IFavoriteResults> {
  version?: number;
}

interface IWorkoutState {
  history: IDataState<IWorkoutHistoryItem[]>;
}

interface IHistoryState {
  enrollments: IDataState<IEnrollmentItem[]>;
  currentSeries: {
    data: ISeries;
  };
}

const fulfilled = (state: IState) => state.history.enrollments.fulfilled;
const loading = (state: IState) => state.history.enrollments.loading;
const loadedAt = (state: IState) => state.history.enrollments.loadedAt;
const items = (state: IState) => state.history.enrollments.data;

const ready = createSelector(
  loadedAt,
  fulfilled,
  (fulfilled, loadedAt) => !loadedAt && fulfilled,
);

const enrolled = createSelector(
  items,
  items => filter(items, { status: 'enrolled' })[0],
);

const currentSeries = (state: IState) => state.history.currentSeries.data;

export const HistorySelectors = {
  enrollments: {
    enrolled,
    items,
    loading,
    fulfilled,
    ready,
  },
  currentSeries,
};




interface WorkoutSelectors extends IContentSelector<IWorkout> {
  byInstructorId: Selector<IState, { [key: string]: IWorkout[] }>,
  new: Selector<IState, IWorkout[]>,
}

export const createWorkoutSelectors = ():WorkoutSelectors => {
  const selectors:IContentSelector<IWorkout> = createContentSelectors<IWorkout>('workout');
  return {
    ...selectors,
    byInstructorId: createSelector(
      selectors.list,
      workouts => {
        const instructorWorkouts:{ [key:string]:IWorkout[] } = {};
        for (const workout of workouts) {
          if (workout.fields.instructor) {
            for (const instructor of workout.fields.instructor) {
              instructorWorkouts[instructor.sys.id] = instructorWorkouts[instructor.sys.id] || [];
              instructorWorkouts[instructor.sys.id].push(workout);
            }
          }
        }
        return instructorWorkouts;
      }
    ),
    new: createSelector(
      selectors.list,
      workouts => {
        const newWorkouts:{ [key:string]:IWorkout } = {};
        for(const workout of workouts) {
          if(workout.fields.new) newWorkouts[workout.sys.id] = workout;
        }
        return Object.values(newWorkouts);
      }
    ),
  };
};




const workout = createWorkoutSelectors();
// const workoutHistory = createWorkoutSelectors();
const seriesChapter = createContentSelectors<ISeriesChapter>('seriesChapter');
const series = createContentSelectors<ISeries>('series');

const workoutsBySeries = createSelector(
  series.list,
  workout.indexedBy.id,
  seriesChapter.indexedBy.id,
  (seriesList, workoutIndex, seriesChapterIndex) => {
    const workoutsBySeries:IContentListIndex<IWorkout> = {};

    for (const series of seriesList) {
      const {
        sys: { id },
        fields: { chapters }
      } = series;

      const workouts:IWorkout[] = [];
      for (const { sys: { id: chapterId }} of chapters || []) {
        const chapter = seriesChapterIndex[chapterId];
        if(!chapter) continue;

        const chapterWorkouts = chapter.fields.workouts || [];
        for (const { sys: { id: workoutId }} of chapterWorkouts) {
          const workout = workoutIndex[workoutId];
          if(!workout) continue;
          workouts.push(workout);
        }
      }
      workoutsBySeries[id] = workouts;
    }

    return workoutsBySeries;
  }
);

export const ContentSelectors: IContentSelectors = {
  series,
  workout,
  seriesChapter,
  benefit:  createContentSelectors<IBenefit>('benefit'),
  bodyFocus:  createContentSelectors<IBodyFocus>('bodyFocus'),
  equipment:  createContentSelectors<IEquipment>('equipment'),
  instructor: createContentSelectors<IInstructor>('instructor'),
  workoutCategory: createContentSelectors<IWorkoutCategory>('workoutCategory'),
  workoutType: createContentSelectors<IWorkoutType>('workoutType'),
  seriesCategory: createContentSelectors<ISeriesCategory>('seriesCategory'),
  asset: createContentSelectors<Asset>('asset'),
  workoutsBySeries,
};

// SDK Selectors
export interface IState {
  content: IContentState;
  favorites: IFavoriteState;
  history: IHistoryState;
  workouts: IWorkoutState;
}

export interface IContentIndex<T> {
  [key: string]: T
};

export interface IContentListIndex<T> {
  [key: string]: T[]
};

export interface IContentSelector<T> {
  list: Selector<IState, T[]>,
  loadedAt: Selector<IState, T[]>,
  indexedBy: {
    id: Selector<IState, IContentIndex<T>>,
  },
}


function createContentSelectors<T extends Entry<any> | Asset>(contentType: string): IContentSelector<T> {

  const raw = (state: IState) => get(state, `content.data.${contentType}`, []);

  const selectParsed = createSelector( raw,
    rawList => {
      const indexedBy: {
        id: IContentIndex<T>,
      } = {
        id: {}, 
      };

      const list = new Array<T>();
      for(const rawItem of rawList) {
        const item:T = <T>rawItem;
        list.push(item)

        for(const [key, index] of Object.entries(indexedBy)) {
          const value = Reflect.get(item.sys, key) || item.fields[key];
          index[value] = item;
        }
      }

      return {
        loadedAt: rawList.loadedAt,
        list,
        indexedBy,
      };
    }
  );

  return {
    loadedAt: createSelector(
      selectParsed,
      parsed => parsed.loadedAt,
    ),
    list: createSelector(
      selectParsed,
      parsed => parsed.list,
    ),
    indexedBy: {
      id: createSelector(
        selectParsed,
        parsed => parsed?.indexedBy?.id
      ),
    }
  };
}
