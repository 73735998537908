import _ from 'lodash';
import produce from 'immer';

export function payloadActions(...actionNames) {
    const actions = {};

    actionNames.forEach((actionName) => {
        actions[actionName] = (args) => args;
    });

    return actions;
}

export const DefaultRoutine = payloadActions(
    'TRIGGER',
    'REQUEST',
    'SUCCESS',
    'FAILURE',
    'FULFILL',
    'CLEAR'
);

const reducers = {
    trigger: (path) => (draft) => {
        const routineDraftState = _.get(draft, path);
        routineDraftState.loading = true;
    },
    request: (path) => (draft) => {
        const routineDraftState = _.get(draft, path);
        routineDraftState._persist = false;
    },
    success: (path) => (draft) => {
        const routineDraftState = _.get(draft, path);
        routineDraftState.failed = false;
        routineDraftState.loadedAt = Date.now();
        delete routineDraftState.error;
    },
    failure: (path) => (draft, { error }) => {
        const routineDraftState = _.get(draft, path);
        routineDraftState.failed = true;
        routineDraftState.error = error;
    },
    fulfill: (path) => (draft) => {
        const routineDraftState = _.get(draft, path);
        routineDraftState.loading = false;
        routineDraftState.fulfilled = true;
    },
};

export function handleActionsImmer(actionsMap, defaultState, routineMap = {}) {
    // one can pass in actions that look like this ACTION_NAME||ACTION_NAME2
    // these should be decomposed one time to ease lookup in the future. Without
    // this logic, this doesn't work with combineActions
    const singleActionsMap = Object.keys(actionsMap).reduce(
        (map, actionName) => {
            const actionNames = actionName.split('||');
            for (let parsedActionName of actionNames) {
                map[parsedActionName] = actionsMap[actionName];
            }
            return map;
        },
        {}
    );

    const routineActionsMap = {};
    _.each(routineMap, (routine, path) => {
        routineActionsMap[routine.trigger] = reducers.trigger(path);
        routineActionsMap[routine.request] = reducers.request(path);
        routineActionsMap[routine.success] = reducers.success(path);
        routineActionsMap[routine.failure] = reducers.failure(path);
        routineActionsMap[routine.fulfill] = reducers.fulfill(path);
    });

    return (state = defaultState, { type, payload }) =>
        produce(state, (draft) => {
            const routineAction = routineActionsMap[type];
            if (routineAction) {
                routineAction(draft, payload);
            }

            const action = singleActionsMap[type];
            return action && action(draft, payload);
        });
}

const DEFAULT_LOCALE = 'en-US';

export class ContentfulModel {
    constructor({ sys, fields }) {
        this.sys = sys;
        this.fields = fields;
    }

    get id() {
        return this.sys.id;
    }

    get sku() {
        return this.field('sku');
    }

    field(key) {
        return _.get(this.fields[key], DEFAULT_LOCALE);
    }
}

export const DefaultInitialState = () => ({
    loading: false,
});
