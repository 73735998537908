import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Menu, Icon } from 'semantic-ui-react';
import { Link } from 'gatsby';
import { findIndex, kebabCase } from 'lodash';

import Selectors from 'src/state/root-selectors';
import { stripTrailingSlash } from 'src/utils/route-utils';
import {
    LOGGED_IN_NAV,
    LOGGED_OUT_NAV,
} from 'src/components/layout/nav-constants';

import headerStyles from 'src/styles/header.module.scss';

const Header = ({ loggedIn, path: currentPath }) => {
    const NAV_ITEMS = loggedIn ? LOGGED_IN_NAV : LOGGED_OUT_NAV;

    const [displaySubnav, setDisplaySubnav] = useState(false);

    /**
     * A navIndex refers to an integer index into the `NAV_ITEMS` array
     */
    const [activeNavIndex, setActiveNavIndex] = useState(null);

    /**
     * a subnavKey refers to a string identifer of a subnav item
     * formatted as `<parent_nav_item_label>-<integer_index_into_parent_subnav_array>`
     */
    const [activeSubnavKey, setActiveSubnavKey] = useState(null);

    /**
     * Returns true if the supplied pathname string matches the current
     * application pathname.
     *
     * @param {String} pathname
     */
    const isCurrentPath = (pathname) =>
        stripTrailingSlash(pathname) === stripTrailingSlash(currentPath);

    /**
     * Generate a unique key for a subnav item
     * using the parent nav item's label
     * and the index in the parents subnav
     *
     * @param {Object} navItem
     * @param {Integer} index
     * @returns {String}
     */
    const getSubnavKey = (parentNavItem, index) =>
        `${kebabCase(parentNavItem.label)}-${index}`;

    /**
     * Given a nav item index, return true if it
     * has an actively selected subnav item
     *
     * @param {Integer} navIndex - index of top-level nav item in `NAV_ITEMS[]`
     * @returns {Boolean}
     */
    const isSubnavActive = (navIndex) => {
        const navItem = NAV_ITEMS[navIndex];

        if (navItem && navItem.subnav) {
            for (const navItm of navItem.subnav) {
                if (navItm.pathname && isCurrentPath(navItm.pathname)) {
                    return true;
                }
            }
        }

        return false;
    };

    const handleNavItemClick = (e) => {
        const targetNavItem =
            e.target.nodeName === 'I' ? e.target.parentElement : e.target;
        const targetNavIndex = parseInt(targetNavItem.dataset.navIndex);

        if (
            targetNavIndex === activeNavIndex &&
            !isSubnavActive(targetNavIndex)
        ) {
            /**
             * If you click on currently active nav item,
             * and it *does not* contain a currently active subnav item,
             * then close the subnav
             *
             */
            setActiveNavIndex(null);
            setDisplaySubnav(false);
        } else if (targetNavIndex !== activeNavIndex) {
            /**
             * If you click on a different nav item,
             * then display the new subnav
             */
            setActiveNavIndex(targetNavIndex);
            setDisplaySubnav(true);
        }
    };

    const handleSubnavItemClick = (e) => {
        const targetSubnavKey = e.target.dataset.subnavKey;
        const targetParentNavIndex = e.target.dataset.parentNavIndex;

        if (targetParentNavIndex !== activeNavIndex) {
            setActiveSubnavKey(targetParentNavIndex);
        }

        if (targetSubnavKey !== activeSubnavKey) {
            setActiveSubnavKey(targetSubnavKey);
        }

        setDisplaySubnav(true);
    };

    const getNavItemProps = (navItem, isSubnavItem = false) => {
        let navItemProps, active;

        if (navItem?.pathname && navItem?.isGatsby) {
            /**
             * Gatsby internal pages should use the Gatsby `Link` Component
             */
            navItemProps = {
                as: Link,
                to: navItem.pathname,
            };
        } else if (navItem?.pathname) {
            /**
             * External pages (i.e., on shopify) uses `a` tags
             */
            navItemProps = {
                as: 'a',
                href: `https://pvolve.com${navItem.pathname}`,
            };
        }

        if (isSubnavItem) {
            /**
             * Use the parent nav item to determine the subnav key
             * subnavKey is of the format `<kebab-case-parent-label>-<integer-index>`
             */
            const parentNavItem = NAV_ITEMS[activeNavIndex];
            const subnavKey = getSubnavKey(
                parentNavItem,
                findIndex(parentNavItem.subnav, navItem)
            );
            active = subnavKey === activeSubnavKey;
        } else {
            active = findIndex(NAV_ITEMS, navItem) === activeNavIndex;
        }

        return {
            active,
            ...navItemProps,
        };
    };

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        NAV_ITEMS.forEach((navItem, navIndex) => {
            /**
             * If main nav has a pathname that matches the current path
             * then mark it as active
             */
            if (navItem.pathname && isCurrentPath(navItem.pathname)) {
                if (navIndex !== activeNavIndex) {
                    setActiveNavIndex(navIndex);
                }
            } else if (navItem.subnav) {
                /**
                 * If sub nav has a pathname that matches the current path
                 * then mark BOTH the main nav and sub nav items as active
                 */
                for (const subnavItem of navItem.subnav) {
                    if (
                        subnavItem.pathname &&
                        isCurrentPath(subnavItem.pathname)
                    ) {
                        const subnavKey = getSubnavKey(
                            navItem,
                            findIndex(navItem.subnav, subnavItem)
                        );

                        if (navIndex !== activeNavIndex) {
                            setActiveNavIndex(navIndex);
                        }

                        if (subnavKey !== activeSubnavKey) {
                            setActiveSubnavKey(subnavKey);
                        }

                        if (!displaySubnav) {
                            setDisplaySubnav(true);
                        }

                        return;
                    }
                }
            }
        });
    }, []);

    return (
        <Grid centered padded="horizontally">
            <Grid.Row>
                <Menu className={headerStyles.headerMenu} secondary>
                    {NAV_ITEMS.map((navItem, index) => (
                        <Menu.Item
                            key={index}
                            className={headerStyles.headerNavItem}
                            onClick={handleNavItemClick}
                            data-nav-index={index}
                            data-nav-pathname={navItem.pathname}
                            {...getNavItemProps(navItem)}
                        >
                            {navItem.label}
                            {navItem.subnav && (
                                <Icon
                                    name={
                                        index === activeNavIndex
                                            ? 'angle up'
                                            : 'angle down'
                                    }
                                    className={headerStyles.headerNavIcon}
                                />
                            )}
                        </Menu.Item>
                    ))}
                </Menu>
            </Grid.Row>

            {displaySubnav && (
                <Grid.Row>
                    <Menu pointing secondary>
                        {activeNavIndex !== null &&
                            NAV_ITEMS[
                                activeNavIndex
                            ].subnav.map((subnavItem, index) => (
                                <Menu.Item
                                    name={subnavItem.label}
                                    key={index}
                                    onClick={handleSubnavItemClick}
                                    data-parent-nav-index={activeNavIndex}
                                    data-pathname={subnavItem.pathname}
                                    data-subnav-key={getSubnavKey(
                                        NAV_ITEMS[activeNavIndex],
                                        index
                                    )}
                                    {...getNavItemProps(subnavItem, true)}
                                />
                            ))}
                    </Menu>
                </Grid.Row>
            )}
        </Grid>
    );
};

Header.propTypes = {
    loggedIn: PropTypes.bool,
};

Header.defaultProps = {
    loggedIn: false,
};

const mapStateToProps = (state) => ({
    loggedIn: Selectors.auth.loggedIn(state),
});

export default connect(mapStateToProps)(Header);
