import { handleActionsImmer } from 'src/utils/redux';
import Actions from 'src/state/root-actions';

const {
    history: { listCurrentSeries, listEnrollments },
} = Actions;

const initialState = {
    enrollments: {
        data: [],
        loading: false,
        fulfilled: false,
        loadedAt: null,
        error: null,
    },
    currentSeries: {
        data: [],
        loading: false,
        loadedAt: null,
        fulfilled: false,
        error: null,
    },
};

const reducer = {
    [listEnrollments.success]: (draft, { series }) => {
        draft.enrollments.data = series;
    },
    [listCurrentSeries.success]: (draft, data) => {
        draft.currentSeries.data = data;
    },
};

const Reducer = handleActionsImmer(reducer, initialState, {
    enrollments: listEnrollments,
});

export default Reducer;
