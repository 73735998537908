import { takeEvery, put, take } from 'redux-saga/effects';
import { routineSaga } from 'src/utils/sagas';
import Actions from 'src/state/root-actions';
import Pvolve from '@pvolve/sdk';

const {
    content: { find },
} = Actions;

class ContentSaga {
    load = function* () {
        for (const contentType of [
            'asset',
            'series',
            'workout',
            'equipment',
            'bodyFocus',
            'instructor',
            'workoutCategory',
            'seriesCategory',
            'seriesChapter',
        ]) {
            yield put(find.trigger({ contentType }));
            yield take(find.fulfill);
        }
    };

    find = routineSaga({
        routine: find,
        *request({ payload: { contentType } }) {
            const response = yield Pvolve.api.content.find(contentType);
            return { contentType, response };
        },
    });
}
const saga = new ContentSaga();

export default function* combined() {
    yield takeEvery(find.trigger, saga.find);
}
