import { createActions } from 'redux-actions';
import { DefaultRoutine } from 'src/utils/redux';

const Actions = createActions({
    CONTENT: {
        FIND: DefaultRoutine,
    },
});

export default Actions;
