import storage from 'redux-persist/lib/storage';

import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';

import {
    TokensReducer as tokens,
    ConfigReducer as config,
} from '@pvolve/sdk/src/redux';
import Content from 'src/state/content';
import HistoryReducer from 'src/state/history/reducer';

const APP_NAME = 'Pvolve';
const key = process.env === 'development' ? `${APP_NAME}-dev` : APP_NAME;

const reducers = {
    config,
    tokens,
    content: Content.Reducer,
    history: HistoryReducer,
};

export default persistReducer(
    {
        storage,
        key,
        whitelist: ['tokens', 'config'],
    },
    combineReducers(reducers)
);
