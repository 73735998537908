import _ from 'lodash';


/**
 * TODO explain that you shouldn't store id, refresh, and access tokens together in cookies
 *
 *
 * for now only store refreshToken in cookies
 */
export default class CookieTokenStore {

  /**
   *
   * @param { cookies } assume an instance of js-cookie
   */
  constructor({
    cookies,
    delegate,
    cookieOptions = {
      refresh: {}
    }
  }) {
    this._cookieOptions = cookieOptions;
    this._delegate = delegate;
    this._cookies = cookies;
  }

  updateTokens(tokens) {
    const { _cookieOptions } = this;

    _.each(tokens, (token, name) => {
      if (_cookieOptions[name]) {
        if ( _.isNil(token) ) {
          this._cookies.remove(name);
        } else {
          this[name] = token;
        }
      }
    });
    this._delegate.updateTokens(tokens);
  }

  clear() {
    this.updateTokens({
      id: null,
      refresh: null,
      access: null,
    });
  }


  get access()  { return this.getToken('access'); }
  get id()      { return this.getToken('id'); }
  get refresh() { return this.getToken('refresh'); }

  set access(value)   { this.setCookie('access', value); }
  set id(value)       { this.setCookie('id', value); }
  set refresh(value)  { this.setCookie('refresh', value); }

  getToken(name) {
    const { _delegate, _cookieOptions, _cookies } = this;
    if (_cookieOptions[name]) {
      const value = _cookies.get(name);
      return _.isEmpty(value) ? null : value;
    } else {
      return _delegate[name];
    }
  }

  setCookie(name, value) {
    const { _cookieOptions, _cookies } = this;
    if (!_cookieOptions[name]) {throw new Error(`not allowed to set ${name} token in cookie`);}
    _cookies.set(name, value, _cookieOptions[name]);
  }

}
