import CookieTokenStore from '../CookieTokenStore';
import ReduxConfigStore from './ReduxConfigStore';
import ReduxTokenStore from './ReduxTokenStore';
import ReduxAuthStore from './ReduxAuthStore';
import configure from '../configure';

export default (coreInstance, options) => {
  const {
    tokenStoreOptions,
    store,
    cookies,
    cookieOptions = {
      refresh: {}
    },
    configStoreOptions,
    authStoreOptions,
  } = options;

  const configStore = new ReduxConfigStore(store, configStoreOptions);
  const delegate = new ReduxTokenStore(store, tokenStoreOptions);
  const tokenStore = options.tokenStore || new CookieTokenStore({
    cookies,
    delegate,
    cookieOptions,
  });
  const authStore = new ReduxAuthStore(store, authStoreOptions);

  return configure(coreInstance, { ...options, tokenStore, configStore, authStore });
};
